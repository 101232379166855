<script setup>
import request from "../request"
import {reactive, ref, watch} from "vue"

const show = ref(false)
const loading = ref(false)
const data = reactive({})
const form = ref(null)

const code = ref(null)

const setupTwoFA = () => {
  loading.value = true

  request.post(process.env.VUE_APP_BASE_URL + '/auth/enable_2fa')
    .then(response => {
      loading.value = false
      data.value = response
    })
    .catch(error => {
      console.log(error)
      loading.value = false
    })
}

watch(show, async (newVal, oldVal) => {
  if (newVal === true) {
    setupTwoFA()
  }
})

const submitCode = () => {

}

const onBeforeClose = (formAction, done) => {
  if (formAction === 'confirm') {
    form.value.validate().then(validated => {
      if (!validated) {
        return
      }

      loading.value = true

      request.post(process.env.VUE_APP_BASE_URL + '/auth/verify_2fa', {code: code.value})
          .then(response => {
            loading.value = false

            done()
          })
          .catch(error => {
            console.log(error)
            loading.value = false
          })
    })
  } else {
    done()
  }
}

defineExpose({
  show
})

</script>

<template>
  <var-style-provider :style-vars="{ '--dialog-width': '420px'}">
    <var-dialog
        v-model:show="show"
        confirm-button-text="Enable"
        cancel-button-text="Cancel"
        @before-close="onBeforeClose"
    >
      <template #title>
        Setup Two-Factor Authentication
      </template>

      <span v-html="data.value?.qr" />

      <var-form ref="form">
        <var-input
            placeholder="Code"
            :rules="[v => !!v || 'Code cannot be empty']"
            v-model="code"
            class="mb-3"
            :disabled="loading"
        />
      </var-form>
    </var-dialog>
  </var-style-provider>
</template>

<style scoped>

</style>