<script setup>
import {onBeforeMount, reactive, ref} from "vue"
import { useStore } from 'vuex'
import request from "../request"
import SetupTwoFAModal from "../components/SetupTwoFAModal.vue"

const store = useStore()
const setupTwoFAModal = ref(null)

const data = reactive({})
const formData = reactive({})
const form = ref(null)

const loading = ref(false)
const loadingUserInfo = ref(false)

const getUserInfo = () => {
  loadingUserInfo.value = true

  request.get(process.env.VUE_APP_BASE_URL + '/me')
      .then(response => {
        loadingUserInfo.value = false
        data.value = response
      })
      .catch(error => {
        loadingUserInfo.value = false
      })
}

const changePassword = () => {
	form.value.validate().then(validated => {
		if (!validated) {
			return
		}

		loading.value = true
		request.put(process.env.VUE_APP_BASE_URL + '/users/' + store.state.user.id + '/change_password', formData)
			.then(response => {
				loading.value = false
				// form.reset()
				alert('good')
			})
			.catch(error => {
				loading.value = false
				console.log(error)
			})
	})
}

const setupTwoFA = (enable) => {
  if (enable) {
    setupTwoFAModal.value.show = true
  } else {
    request.post(process.env.VUE_APP_BASE_URL + '/auth/disable_2fa')
        .then(response => {
          loading.value = false
        })
        .catch(error => {
          console.log(error)
          loading.value = false
        })
  }
}

onBeforeMount(() => {
  getUserInfo()
})

</script>

<template>
	<h3>Change password</h3>
	<hr>
	<var-row>
		<var-col :span="8">
			<var-form ref="form">
				<var-input
					placeholder="Old password"
					:rules="[v => !!v || 'Old password cannot be empty']"
					v-model="formData.old_password"
					class="mb-3"
					type="password"
				/>
				<var-input
					placeholder="New password"
					:rules="[v => !!v || 'New password cannot be empty']"
					v-model="formData.new_password"
					class="mb-3"
					type="password"
				/>
				<var-input
					placeholder="Confirm new password"
					:rules="[v => !!v || 'Password confirmation cannot be empty', v => v === formData.new_password || 'Password confirmation does not match New Password']"
					v-model="formData.new_password_confirmation"
					class="mb-3"
					type="password"
				/>
			</var-form>

			<var-button @click="changePassword" type="primary" :loading="loading">Update password</var-button>
		</var-col>
	</var-row>

  <template v-if="!loadingUserInfo">
    <h3>Two-factor Authentication</h3>
    <hr>
    <var-row>
      <var-col>
        <h6>Two FA Enabled</h6>
        <var-switch v-model="data.value.two_fa_enabled" @change="setupTwoFA" />
      </var-col>
    </var-row>

    <setup-two-f-a-modal ref="setupTwoFAModal" />
  </template>
</template>

<style scoped>

</style>